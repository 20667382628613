import { Col, Row } from "antd"
import { graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import React, { useMemo } from "react"
import ContactUs from "../../components/contactus"
import FAQ from "../../components/faq"
import HomePage from "../../components/homepage"
import "../../styles/magnus-platform.sass"
import useBreakpoint, { smallScreens } from "../../utils/useBreakpoint"

import faqContent from "../../resources/faq-content.json"

const styles = {
  imageWrapperStyle: { position: "initial" },
  analyticsDashboardPreviewLeftStyles: {
    left: "-8%",
    top: "43%",
    objectFit: "contain",
    transform: "scale(1.7)",
  },
  analyticsDashboardPreviewRightStyles: {
    left: "28.5%",
    top: "-47%",
    objectFit: "contain",
    transform: "scale(1.5)",
  },
  // First section images
  analyticsDashboardTankReportsGridStyles: {
    transition: "transform 0.3s ease-in-out",
    willChange: "transform",
    left: "16.5%",
    top: "-15.5%",
    objectFit: "contain",
    transform: "scale(2)",
  },
  analyticsDashboardTankReportsViewStyles: {
    transition: "transform 0.3s ease-in-out",
    willChange: "transform",
    left: "5%",
    top: "-20%",
    objectFit: "contain",
    height: 'initial',
  },
  // Second section images.
  analyticsNeighborTanksGridStyles: {
    transition: "transform 0.3s ease-in-out",
    willChange: "transform",
    left: 0,
    top: 0,
    objectFit: "contain",
    transform: "scale(2)",
  },
  analyticsNeighborTanksViewStyles: {
    transition: "transform 0.3s ease-in-out",
    willChange: "transform",
    left: "0.5%",
    top: 0,
    objectFit: "contain",
    transform: "scale(1.25)",
  },
  analyticsDashboardViewStyles: {
    transition: "transform 0.3s ease-in-out",
    willChange: "transform",
    left: "5.5%",
    top: "-20.5%",
    objectFit: "contain",
    transform: "scale(1.05)",
    height: 'initial',
  },
  // Third section images.
  analyticsMarketingTanksGridStyles: {
    transition: "transform 0.3s ease-in-out",
    willChange: "transform",
    left: "-20.5%",
    top: "22.5%",
    objectFit: "contain",
    transform: "scale(2)",
  },
  analyticsMarketingViewStyles: {
    transition: "transform 0.3s ease-in-out",
    willChange: "transform",
    objectFit: "contain",
  },
  heroImageContainerStyle: { height: '100%' },
  heroImageStyle: { objectFit: 'cover', objectPosition: '70% 10%' },
}

const IndexPage = ({ data }) => {
  const { breakpoint, isSmallScreen: reverseOrder } = useBreakpoint()
  const isMobileScreen = breakpoint === "sm" || breakpoint === "xs"
  const heroSectionClasses = React.useMemo(() => {
    const baseClasses = ["magnus-analytics-hero-section"]
    if (isMobileScreen) {
      baseClasses.push("magnus-analytics-hero-section-mobile")
    }
    return baseClasses.join(' ')
  }, [isMobileScreen])
  return (
    <HomePage>
      <div className="magnus-dashboard-container">
          <section className={heroSectionClasses}>
            <Img
              style={styles.heroImageContainerStyle}
              fluid={data.heroImage.childImageSharp.fluid}
              imgStyle={styles.heroImageStyle}
              alt="magnus-analytics-neighbor-report-grid-preview"
            />
            <div className="magnus-analytics-hero-section-text">
              <div className="magnus-analytics-hero-section-text-title">
                <Img
                  style={{ height: 25, width: 25 }}
                  fluid={data.logoImage.childImageSharp.fluid}
                  alt="magnus-analytics-dashboard-preview-1"
                />
                <h1>{isMobileScreen ? 'magnus platform' : 'magnus.'}</h1>
              </div>
              <div>
              <h2 className="magnus-analytics-hero-section-text-sub-title">
                An advanced platform for outstanding hardware.
              </h2>
              <p className="magnus-analytics-hero-section-text-description">
                We use AI and Machine Learning to manage our innovative radar
                level monitors and generate actionable insights of your business
              </p>
              </div>
            </div>
          </section>
        <section className="magnus-analytics-dashboard-reports">
          <Row gutter={50} justify="space-between">
            <Col
              order={reverseOrder ? 2 : 1}
              xl={15}
              lg={15}
              md={24}
              sm={24}
              xs={24}
            >
                <div className="magnus-analytics-dashboard-reports-preview">
                  <Img
                    imgStyle={styles.analyticsNeighborTanksGridStyles}
                    style={styles.imageWrapperStyle}
                    fluid={
                      data.analyticsDashboardTankReportsGrid.childImageSharp
                        .fluid
                    }
                    alt="magnus-analytics-dashboard-report-grid-preview"
                  />
                  <Img
                    className="magnus-analytics-tank-reports-preview-view"
                    imgStyle={styles.analyticsDashboardViewStyles}
                    style={styles.imageWrapperStyle}
                    fluid={data.analyticsDashboardPreview.childImageSharp.fluid}
                    alt="magnus-analytics-dashboard-report-view-preview"
                  />
                </div>
            </Col>
            <Col
              order={reverseOrder ? 1 : 2}
              xl={8}
              lg={8}
              md={24}
              sm={24}
              xs={24}
            >
                <div className="magnus-analytics-neighbor-reports-title">
                    <h1>ANALYTICS DASHBOARD</h1>
                </div>
                <div className="magnus-analytics-neighbor-reports-text">
                  <p>
                    Our easy to use dashboard allows you to use your PC, Laptop
                    or Tablet to monitor all of your customer’s containers. This
                    provides the visibility and data required to make more
                    informed decisions which lead to greater efficiency.
                  </p>
                </div>
            </Col>
          </Row>
        </section>
        <section className="magnus-analytics-tank-reports">
          <Row gutter={50} justify="space-between">
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                <div className="magnus-analytics-tank-reports-title">
                    <h1>tank reports</h1>
                </div>
                <div className="magnus-analytics-tank-reports-text">
                  <p>
                    A cloud based solution with actionable analytics allows you
                    to predict, plan and manage stock as well as workload and
                    capacity and by regular servicing allows for a more
                    predictable cash flow. Efficient planning means fewer trips
                    and with automated warnings cuts out the emergencies.
                  </p>
                </div>
            </Col>
            <Col xl={15} lg={15} md={24} sm={24} xs={24}>
                <div className="magnus-analytics-tank-reports-preview">
                  <Img
                    className="magnus-analytics-tank-reports-preview-grid"
                    imgStyle={styles.analyticsDashboardTankReportsGridStyles}
                    style={styles.imageWrapperStyle}
                    fluid={
                      data.analyticsDashboardTankReportsGrid.childImageSharp
                        .fluid
                    }
                    alt="magnus-analytics-tank-report-grid-preview"
                  />
                  <Img
                    className="magnus-analytics-tank-reports-preview-view"
                    imgStyle={styles.analyticsDashboardTankReportsViewStyles}
                    style={styles.imageWrapperStyle}
                    fluid={
                      data.analyticsDashboardTankReportsView.childImageSharp
                        .fluid
                    }
                    alt="magnus-analytics-tank-report-view-preview"
                  />
                </div>
            </Col>
          </Row>
        </section>
        <section className="magnus-analytics-neighbor-reports">
          <Row gutter={50} justify="space-between">
            <Col
              order={reverseOrder ? 2 : 1}
              xl={15}
              lg={15}
              md={24}
              sm={24}
              xs={24}
            >
                <div className="magnus-analytics-neighbor-reports-preview">
                  <Img
                    imgStyle={styles.analyticsNeighborTanksGridStyles}
                    style={styles.imageWrapperStyle}
                    fluid={
                      data.analyticsDashboardTankReportsGrid.childImageSharp
                        .fluid
                    }
                    alt="magnus-analytics-neighbor-report-grid-preview"
                  />
                  <Img
                    className="magnus-analytics-tank-reports-preview-view"
                    imgStyle={styles.analyticsNeighborTanksViewStyles}
                    style={styles.imageWrapperStyle}
                    fluid={
                      data.analyticsDashboardNeighborReportView.childImageSharp
                        .fluid
                    }
                    alt="magnus-analytics-neighbor-report-view-preview"
                  />
                </div>
            </Col>
            <Col
              order={reverseOrder ? 1 : 2}
              xl={8}
              lg={8}
              md={24}
              sm={24}
              xs={24}
            >
                <div className="magnus-analytics-neighbor-reports-title">
                    <h1>neighbours</h1>
                </div>
                <div className="magnus-analytics-neighbor-reports-text">
                  <p>
                    Reduction in carbon footprint due to reduction in multiple
                    trips to same location and therefore less wear and tear on
                    delivery/collection vehicles. This single platform gives
                    view of all clients.
                  </p>
                </div>
            </Col>
          </Row>
        </section>
        <section className="magnus-analytics-marketing">
          <Row gutter={50} justify="space-between">
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                <div className="magnus-analytics-marketing-title">
                    <h1>marketing</h1>
                </div>
                <div className="magnus-analytics-marketing-text">
                  <p>
                    Create targeted marketing campaigns from the dashboard, such
                    as cold weather campaigns or special offers, and interact
                    directly with customers in sync with the Magnus App.
                  </p>
                  <p>
                    Reduce the need for flyers and texts and improve conversion
                    rates.
                  </p>
                </div>
            </Col>
            <Col xl={15} lg={15} md={24} sm={24} xs={24}>
                <div className="magnus-analytics-marketing-preview">
                  <Img
                    imgStyle={styles.analyticsMarketingTanksGridStyles}
                    style={styles.imageWrapperStyle}
                    fluid={
                      data.analyticsDashboardTankReportsGrid.childImageSharp
                        .fluid
                    }
                    alt="magnus-analytics-marketing-grid-preview"
                  />
                  <Img
                    className="magnus-analytics-tank-reports-preview-view"
                    imgStyle={styles.analyticsMarketingViewStyles}
                    style={styles.imageWrapperStyle}
                    fluid={data.marketingView.childImageSharp.fluid}
                    alt="magnus-analytics-marketing-view-preview"
                  />
                </div>
            </Col>
          </Row>
        </section>
      </div>
      <FAQ content={faqContent} />
      <ContactUs />
    </HomePage>
  )
}

export default IndexPage

export const query = graphql`
  query {
    analyticsDashboardPreview: file(
      relativePath: { eq: "analytics-dashboard-preview.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    analyticsDashboardTankReportsGrid: file(
      relativePath: { eq: "magnus-analytics-tank-reports-grid.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    analyticsDashboardTankReportsView: file(
      relativePath: { eq: "magnus-analytics-tank-report-view.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    analyticsDashboardNeighborReportView: file(
      relativePath: { eq: "analytics-dashboard-neighbor-report-view.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    marketingView: file(relativePath: { eq: "marketing-view.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    heroImage: file(relativePath: { eq: "magnus-platform-hero-image.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logoImage: file(relativePath: { eq: "magnus-logo.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
